import React, { useState } from 'react';
import { getPublicSeat, SeatInfo } from '../services/api';
import '../styles/ExamInfoPage.scss';

const ExamInfoPage: React.FC = () => {
  const [name, setName] = useState('');
  const [id, setId] = useState('');
  const [seatInfo, setSeatInfo] = useState<SeatInfo | null>(null);
  const [errorMessage, setErrorMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [queryType, setQueryType] = useState('1'); // '1' 为学生，'2' 为教师

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsLoading(true);
    setSeatInfo(null);
    setErrorMessage('');

    const isStudent = queryType === '1';

    try {
      const response = await getPublicSeat({
        [isStudent ? 'student_name' : 'teacher_name']: name,
        [isStudent ? 'student_id' : 'teacher_id']: id,
        house_type_id: Number(queryType),
      });

      if (response.code === 0 && response.data) {
        setSeatInfo(response.data);
      } else {
        setErrorMessage(response.message);
      }
    } catch (error) {
      setErrorMessage('查询失败，请稍后再试');
    } finally {
      setIsLoading(false);
    }
  };

  // 添加日期格式化函数
  const formatDate = (dateString: string): string => {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const day = date.getDate();
    return `${year}年${month.toString().padStart(2, '0')}月${day.toString().padStart(2, '0')}日`;
  };

  return (
    <div className="exam-info-page">
      <div className="container">
        <h1 className="title">工位查询系统</h1>
        <form onSubmit={handleSubmit}>
          <div className="input-group">
            <label className="input-label" htmlFor="queryType">查询类型</label>
            <select
              className="input-field"
              id="queryType"
              value={queryType}
              onChange={(e) => setQueryType(e.target.value)}
            >
              <option value="1">学生查询</option>
              <option value="2">教师查询</option>
            </select>
          </div>
          <div className="input-group">
            <label className="input-label" htmlFor="id">
              {queryType === '1' ? '学生学号' : '教师工号'}
            </label>
            <input
              className="input-field"
              id="id"
              type="text"
              value={id}
              onChange={(e) => setId(e.target.value)}
              required
            />
          </div>
          <div className="input-group">
            <label className="input-label" htmlFor="name">
              {queryType === '1' ? '学生姓名' : '教师姓名'}
            </label>
            <input
              className="input-field"
              id="name"
              type="text"
              value={name}
              onChange={(e) => setName(e.target.value)}
              required
            />
          </div>
          <button className="query-button" type="submit" disabled={isLoading}>
            {isLoading ? '查询中...' : '查询'}
          </button>
        </form>
        {errorMessage && <div className="error-message">{errorMessage}</div>}
        {seatInfo && (
          <div className="exam-info">
            <h2 className="title">工位信息</h2>
            <div className="info-item">
              <span className="info-label">校区:</span>
              <span className="info-value">{seatInfo.campus_name}</span>
            </div>
            <div className="info-item">
              <span className="info-label">楼栋:</span>
              <span className="info-value">{seatInfo.building_name}</span>
            </div>
            <div className="info-item">
              <span className="info-label">楼层:</span>
              <span className="info-value">{seatInfo.floor_name}</span>
            </div>
            <div className="info-item">
              <span className="info-label">房间号:</span>
              <span className="info-value">{seatInfo.house_name}</span>
            </div>
            <div className="info-item">
              <span className="info-label">工位号:</span>
              <span className="info-value">{seatInfo.number}</span>
            </div>
            <div className="info-item">
              <span className="info-label">所属系部:</span>
              <span className="info-value">{seatInfo.department_type_name}</span>
            </div>
            {queryType === '1' ? (
              <>
                <div className="info-item">
                  <span className="info-label">学生类型:</span>
                  <span className="info-value">{seatInfo.student_type_name}</span>
                </div>
                <div className="info-item">
                  <span className="info-label">导师姓名:</span>
                  <span className="info-value">{seatInfo.teacher_name}</span>
                </div>
              </>
            ) : (
              <>
                <div className="info-item">
                  <span className="info-label">研究方向:</span>
                  <span className="info-value">{seatInfo.research_direction}</span>
                </div>
                <div className="info-item">
                  <span className="info-label">性别:</span>
                  <span className="info-value">{seatInfo.gender}</span>
                </div>
                <div className="info-item">
                  <span className="info-label">出生日期:</span>
                  <span className="info-value">{seatInfo.birthday ? formatDate(seatInfo.birthday) : '未提供'}</span>
                </div>
                <div className="info-item">
                  <span className="info-label">是否为研究生导师:</span>
                  <span className="info-value">{seatInfo.is_graduate_supervisor ? '是' : '否'}</span>
                </div>
              </>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default ExamInfoPage;
import axios from "axios";

const BASE_URL = "https://express.saroz.top";

// 定义座位信息的接口
export interface SeatInfo {
  number: string;
  status: string;
  house_name: string;
  floor_name: string;
  building_name: string;
  campus_name: string;
  department_type_name: string;
  student_type_name?: string;
  teacher_name?: string;
  research_direction?: string;
  gender?: string;
  birthday?: string;
  is_graduate_supervisor?: boolean;
}

// 定义查询参数的接口
interface GetPublicSeatParams {
  student_name?: string;
  student_id?: string;
  teacher_name?: string;
  teacher_id?: string;
  house_type_id?: number;
}

// 定义API响应的接口
interface ApiResponse<T> {
  code: number;
  data: T | null;
  message: string;
}

// 获取公共座位信息的函数
export const getPublicSeat = async (
  params: GetPublicSeatParams
): Promise<ApiResponse<SeatInfo>> => {
  try {
    const response = await axios.get<ApiResponse<SeatInfo>>(
      `${BASE_URL}/public/search/student`,
      {
        params: {
          student_name: params.student_name,
          student_id: params.student_id,
          teacher_name: params.teacher_name,
          teacher_id: params.teacher_id,
          house_type_id: params.house_type_id || 1,
        },
      }
    );
    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      return error.response.data as ApiResponse<SeatInfo>;
    } else {
      throw error;
    }
  }
};
